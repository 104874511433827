import "./index.css"
import App from "./App"
import ReactDOM from "react-dom"
import { StrictMode } from "react"

ReactDOM.render(
     <StrictMode>
          <App />
     </StrictMode>,
     document.getElementById("root")
)
