import "./home.css"

function Home() {
     return (
          <div className="home">
               <div className="intro2">
               
                    <img src="/img/Robomettsnature.png" className="cover" alt="background">
                    </img>
                    <div className="teaser">
                         It´s facinating to see how our Robots getting in touch with nature
                         <br></br>
                         they´re keeping your Plants alife and looking for their needs 
                         <br></br> 
                         The results stand for theirselfs



                    </div>
               </div>
               
          </div>
     )
}

export default Home
