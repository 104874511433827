import { BrowserRouter, Route, Routes } from "react-router-dom"
import "./App.css"
import About from "./Components/AboutComponent/about"
import Email from "./Components/EmailComponent/email"
import Footer from "./Components/FooterComponent/footer"
import Header from "./Components/HeaderComponent/header"
import Home from "./Components/HomeComponent/home"
import Nutribot from "./Components/NutribotComponent/nutribot"
import NutribotLiving from "./Components/NutribotLivingComponent/nutribotliving"
import Start from "./Components/StartComponent/start"
import Tutorial from "./Components/TutorialComponent/tutorial"


function App() {
     return (
          <BrowserRouter>
               <Header />
               <Routes>
               <Route exact path="/" element={<Home/>} />
               <Route exact path="/bot" element={<Nutribot/>} />
               <Route exact path="/botl" element={<NutribotLiving/>} />
               <Route exact path="/tut" element={<Tutorial/>} />
               <Route exact path="/start" element={<Start/>} />
               <Route exact path="/kontakt" element={<Email/>} />
               <Route exact path="/about" element={<About/>} />
               </Routes>
               <Footer/>
          </BrowserRouter>
     )
}

export default App
