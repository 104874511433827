import "./tutorial.css"


function Tutorial() {
     return (
          <div className="home">
               <div className="intro">
               
               <div className="teaser">
                         The Dashboard
                         
               </div>
               <br></br>
               <img src="/img/dashboard_graph.png" className="cover3" alt="nutribot"></img>
               <p className="paragraph elem1">
                         The Graph shows the Ec value of the soil. The Feeder is the ultimate watering Robot for your grow.

                         

                         It measures and monitores the moisture of your Pots. Once the pots get watered, the EC value comes to a peak.

                        
                         After some time the EC value drops because of lack of water in the soil. You can choose your own threshold to activate the pumps.

                         
                        
                         When the line hits the treshhold, the feeder feeds the estimated amount of water to your plants.
                        
                         This is the whole magic, but it works great!
                         </p>

                         <br></br>

                         <img src="/img/dashboard_fields.png" className="cover4" alt="nutribot"></img>
                         
                         <p className="paragraph elem2">
                         The Fields Pump1 to Pump4 are used to determine the amount of water each pump delivers.
                         

                         You can change it by entering the new value to the textbox next to the Pump field and then hit change button. Make sure you change all 4 at once.
                         
                         Below are the Fields to enter a timer for your feeding. You can determine the time with the corresponding fields of month, day,hour and minute. 
                         
                         
                         To set your timer more than once, enter 111 to the day or month or both.This is used for the wildcard.
                    
                         
                         
                         The Feed Button activates the feeding of all 4 pumps. You can also water with just one pump.
                         
                         If you hit Auto ON the automatic feedingmode described is running. You can simply switch it off by hitting Auto OFF.
                        
                         The last Fields sets the desired treshhold. Every treshold corresponds to a sensor and to a pump.
                         
                         This is the whole magic, thats all!
                         </p>



               </div>
               
          </div>
     )
}

export default Tutorial
