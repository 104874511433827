import detectBrowserLanguage from "detect-browser-language"
import { Link } from "react-router-dom"
import useStore from "../ZustandManager/zustand.js"
import "./header.css"
function Header() {
     let language = useStore((state) => state.language)
     const setLanguage = useStore((state) => state.setLanguage)
     let lang = detectBrowserLanguage()

     if (lang === "de-AT") {
          setLanguage("de")
     } else if (lang === "en-US") {
          setLanguage("en")
     } else if (lang === "de") {
          setLanguage("de")
     } else if (lang === "it-IT") {
          setLanguage("it")
     } else if (lang === "it") {
          setLanguage("it")
     } else {
          setLanguage("en")
     }

     function baren() {
          if (language !== "none") {
               if (language === "de") {
                    return "Bären und Drachen"
               } else if (language === "en") {
                    return "Teddybears and Dragons"
               } else if (language === "it") {
                    return "Orsetti e draghi"
               } else {
                    return "Teddybears and Dragons"
               }
          }
     }
     function me() {
          if (language !== "none") {
               if (language === "de") {
                    return "Über mich"
               } else if (language === "en") {
                    return "About Me"
               } else if (language === "it") {
                    return "chi sono"
               } else {
                    return "About Me"
               }
          }
     }

     function kleidung() {
          if (language !== "none") {
               if (language === "de") {
                    return "Baby und Kinderbekleidung"
               } else if (language === "en") {
                    return "baby and child clothes"
               } else if (language === "it") {
                    return "abbiglamento da bambini"
               } else {
                    return "baby and child clothes"
               }
          }
     }
     function accessoirs() {
          if (language !== "none") {
               if (language === "de") {
                    return "Accessoires"
               } else if (language === "en") {
                    return "accessory"
               } else if (language === "it") {
                    return "accessori"
               } else {
                    return "accessory"
               }
          }
     }

     function kontakt() {
          if (language !== "none") {
               if (language === "de") {
                    return "Kontakt"
               } else if (language === "en") {
                    return "contact"
               } else if (language === "it") {
                    return "contatto"
               } else {
                    return "contact"
               }
          }
     }

     return (
          <div className="Header">
               <nav id="header">
                    <Link to="/" className="nav-link home">
                         Home
                    </Link>

                    <div
                         className="nav-link gal dropdown"
                         onClick={() => {
                              const dropdownParent = document.getElementById("dropdownshop")
                              if (dropdownParent.classList.contains("expande")) {
                                   dropdownParent.classList.remove("expande")
                              } else {
                                   dropdownParent.classList.add("expande")
                              }
                         }}>
                         Feeder
                         <div className="dropdown-menu-shop dropdownparentshop" id="dropdownshop">
                              
                         <Link className="noline" to="/botl">
                                   <div className="dropdownelementshop">Living Soil</div>
                              </Link>
                              
                              
                              
                              <Link className="noline" to="/bot">
                                   <div className="dropdownelementshop">Fertilizer Feed</div>
                              </Link>

                              

                              <Link className="noline" to="/tut">
                                   <div className="dropdownelementshop">Dashboard Overview</div>
                              </Link>
                              <Link className="noline" to="/start">
                                   <div className="dropdownelementshop">Getting Started</div>
                              </Link>
                         </div>
                    </div>
                    <Link to="/" className="logo">
                          <img src="img/logo.png" className="img"  width="400"></img>
                         
                        
                    </Link>
                    <div
                         id="dropdownabout"
                         className="nav-link about dropdown"
                         >
                         
                        
                              <Link className="noline" to="/about">
                              {me()}    
                              </Link>

                    </div>
                    <Link to="/kontakt" className="nav-link impr">
                         {kontakt()}
                    </Link>

                    <div id="social">
                         <a className="social-icon" data-tooltip="philovescoding@gmail.com" data-toggle="tooltip" data-placement="top" title="E-mail" href="mailto:office@feeder.at">
                              <i className="fa fa-envelope" aria-hidden="true"></i>
                         </a>

                         
                         <a className="social-icon" data-tooltip="Instagram" data-toggle="tooltip" data-placement="top" title="instagram" href="https://www.instagram.com/feeder_bot/">
                              <i className="fa fa-instagram" aria-hidden="true"></i>
                         </a>

                         
                    </div>
               </nav>
          </div>
     )
}

export default Header
